import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, Popover, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../CommonUi/TableComponent";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import { deleteSaleInvoiceDraft, getAllSaleInvoiceDrafts } from "../../redux/rtk/features/sale/saleSlice";
import moment from "moment";
import { size } from "lodash";

const DraftList = (props) => {

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const searchValue = query.get("q");
    const dispatch = useDispatch();

    const {
        draftList,
        draftListLoading: loading
    } = useSelector((state) => state.sales);

    //Delete Customer
    const onDelete = async (id) => {
        const deleteSuccessCallBack = async () => {
            try {
                dispatch(getAllSaleInvoiceDrafts({ q: searchValue }));
                toast.warning(`Draft : ${id} is removed `);
                return navigate("/admin/sale/draft");
            } catch (error) {
                console.log("Error while loading drafts:", error.message);
            }
        };
        try {
            await dispatch(deleteSaleInvoiceDraft({ id, deleteSuccessCallBack }));
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleEdit = (data) => {
        navigate("/admin/sale/add", { state: { data: data?.data, draftId: data.id } });
    }
    
	const invoiceData = useSelector((state) => state.accounts.appSettings);

    const columns = [
        {
            id: 1,
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 0.8 * 100,
        },
        {
            id: 2,
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
            render: (name, { customerId }) => (<Link to={`/admin/customer/${customerId}`}>{name}</Link>),
        },
        invoiceData?.show_table_number? 
        {
            id: 8,
            title: "Table No",
            dataIndex: "tableNo",
            key: "tableNo",
            width: 1.2 * 100,
            render: (text, record) => record.data?.tableNo,
          } : null,
        {
            id: 3,
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            width: 1.2 * 100,
        },
        {
            id: 4,
            title: "State",
            dataIndex: "state",
            key: "state",
            width: 1.3 * 100,
        },
        {
            id: 5,
            title: "No Of Products",
            dataIndex: "noOfProducts",
            key: "noOfProducts",
            width: 1.3 * 100,
        },
        {
            id: 6,
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: 1.2 * 100,
            render: (date) => date ? moment(date).format("DD-MM-YYYY") : "-"
        },
        {
            id: 7,
            title: "Action",
            key: "action",
            render: (data, { id }) => (
                <div className="flex">
                    <Tooltip title="Edit">
                        <Button
                            type='primary'
                            className="flex justify-center w-[38px] h-[30px] items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded mr-2"
                            shape='round'
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(data)}
                        ></Button>
                    </Tooltip>
                    <Popover
                        className="mr-2"
                        content={
                            <a onClick={() => onDelete(id)}>
                                <Button type='primary' danger>
                                    Yes Please !
                                </Button>
                            </a>
                        }
                        placement="topLeft"
                        title='Are you sure you want to delete ?'
                        trigger='click'
                    >
                        <Tooltip title="Delete">
                            <Button
                                className="flex justify-center w-[38px] bg-red-500 hover:bg-red-700 h-[30px] items-center text-white font-bold py-2 px-3 rounded"
                                shape='round'
                                icon={<DeleteOutlined />}
                            ></Button>
                        </Tooltip>
                    </Popover>
                </div>
            ),
        },
    ];
    const filteredColumns = columns.filter(column => column !== null);

    useEffect(() => {
        dispatch(getAllSaleInvoiceDrafts({ q: searchValue }));
    }, [dispatch, searchValue]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.q);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        queryParams.set('q', e.target.value);
        navigate(`?${queryParams.toString()}`);
    };

    return (
        <Card className='border-0 md:border md:p-6 bg-transparent md:bg-[#fafafa] dark:bg-[#2a2931]' bodyStyle={{ padding: 0 }
        }>
            <div className='lg:flex items-center justify-between pb-3'>
                <h1 className='text-lg font-bold'>Sale Invoice Drafts</h1>
                <Input className="w-96" placeholder='Search Drafts' value={searchValue} onChange={handleInputChange} />
            </div>
            <UserPrivateComponent permission={"readAll-customer"}>
                <TableComponent
                    columns={filteredColumns}
                    csvFileName={"Drafts"}
                    // paginatedThunk={loadAllCustomer}
                    list={draftList}
                    total={size(draftList)}
                    loading={loading}
                />
            </UserPrivateComponent>
        </Card >
    );
};

export default DraftList;
