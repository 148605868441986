// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddProd_importCsvCard__Z96ai {
  height: 100%;
}

.AddProd_addProductBtnContainer__k848- {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/product/AddProd.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".importCsvCard {\n  height: 100%;\n}\n\n.addProductBtnContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importCsvCard": `AddProd_importCsvCard__Z96ai`,
	"addProductBtnContainer": `AddProd_addProductBtnContainer__k848-`
};
export default ___CSS_LOADER_EXPORT___;
