import { Button } from "antd";
import React, {
    forwardRef,
    Fragment,
    useMemo,
    useRef,
} from "react";
import { useReactToPrint } from "react-to-print";
// import "./style.css";
import _ from "lodash"
import { DECIMAL_NUMBER, getMomentObj, isValidUrl } from "../../utils/functions";
import { useSelector } from "react-redux";

// import { saveAs } from "file-saver";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {

    const converter = require('number-to-words')
    const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
    const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);
    const word = converter.toWords(data?.totalAmount || 0);
    const words = converter.toWords(data?.vatAmount || 0);

    const totalInWords = word.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());
    const taxInWords = words.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());

    // for table data
    const filteredData = useMemo(() => {
        return _.filter(isQuotation ? data.quotationProduct : data.saleInvoiceProduct, (each) => each)
    }, [data, isQuotation]);

    // for gst cumulative info table data
    const groupedGstData = useMemo(() => {
        const keyToGrp = _.get(filteredData, "0.productGST") ? "productGST" : "vatPrice";
        const groupByGst = _.groupBy(filteredData, keyToGrp);
        const result = []
        _.forEach(_.entries(groupByGst), ([key, value]) => {
            if (key > 0) {
                let sum = 0
                _.forEach(value, (prod) => {
                    let price = prod.productSalePrice;
                    if (prod.inclusiveGst) {
                        price = prod.productSalePrice / (1 + prod[keyToGrp] / 100)
                    }
                    sum += price * prod.productQuantity
                })
                result.push({
                    productGST: key,
                    taxableValue: sum
                })
            }
        })
        return result;
    }, [filteredData]);

    const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

    const getProductGst = (product, forCalculation = false) => {
        if (product.inclusiveGst && forCalculation) {
            return 0;
        }
        return _.get(product, "productGST") || _.get(product, "vatPrice") || 0;
    }
    return (
        <Fragment>
            <div ref={ref} className="p-5">
                {/* <div className="ml-80 flex justify-between "> */}

                {/* <div className=" pr-2 font-light text-sm"> (DUPLICATE FOR TRANSPORTER)</div> */}

                {/* </div> */}
                <div className="border border-[#abaaaa] text-sm ">
                    <h1 className="border-b p-0.5 border-[#abaaaa] font-bold text-base flex justify-center"> {isQuotation ? "QUOTATION" : "INVOICE"} </h1>
                    <div className="grid grid-cols-2 text-left border-b border-[#abaaaa]">
                        <div className="col-span-1 border-r border-[#abaaaa] px-2 ">
                            <div className=" font-bold">{invoiceData?.companyName}</div>
                            {invoiceData?.address && <div className="">{invoiceData?.address} </div>}
                            {invoiceData?.address && <div className="">{invoiceData?.address_l2 && invoiceData?.address_l2} </div>}
                            <div className="">State Name : </div>
                            {invoiceData?.phone && <div className="">Cell : {invoiceData?.phone}</div>}
                            {invoiceData?.email && <div className="">E-Mail : {invoiceData?.email}</div>}
                            {invoiceData?.gst_no && <div className="mt-2">GSTIN : {invoiceData?.gst_no}</div>}
                        </div>

                        <div className="col-span-1 ">
                            <div>
                                {isValidUrl(invoiceData?.companyLogo) && <img className="p-0.5 min-w-[50px] max-w-[150px] min-h-[50px] max-h-[80px] object-contain m-auto" src={invoiceData?.companyLogo}></img>}
                            </div>
                            <div className="w-full flex">
                                <div className="w-1/3 text-right">
                                    <div className="font-bold">{isQuotation ? "QUOTATION" : "Invoice"} No. :</div>
                                    <div className="font-bold">{isQuotation ? "QUOTATION" : "Invoice"} Term : </div>
                                    <div className="font-bold">{isQuotation ? "QUOTATION" : "Invoice"} Date : </div>
                                </div>
                                <div className="w-2/3 pl-2">
                                    <div className=" ">{data?.manualId}</div>
                                    <div className=" ">CREDIT</div>
                                    <div className=" ">{getMomentObj(data?.date).format("DD-MM-YYYY")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-left ">
                        <div className="col-span-1 flex gap-2 border-r border-[#abaaaa] px-2">
                            <div className=" font-bold">Buyer:</div>
                            <div>
                                {data?.customer?.name && <div className="">{data?.customer?.name}</div>}
                                {data?.customer?.address && <div className="">{data?.customer?.address} </div>}
                                {data?.customer?.phone && <div className="">Cell No :{data?.customer?.phone} </div>}
                                {/* {data?.customer?.address && <div className="">{data?.customer?.address} </div>} */}
                                {data?.customer?.gst_no && <div className="">GSTIN : {data?.customer?.gst_no}</div>}
                                {data?.customer?.state && <div className="pb-1">State Name : {data?.customer?.state}</div>}
                            </div>
                        </div>
                        <div className="col-span-1  border-[#abaaaa] px-2">
                            <div className=" font-bold">Shipped To.</div>
                            {data?.customer?.name && <div className=" ">{data?.customer?.name}</div>}
                            {data?.customer?.address || data?.address ? (
                                <div className="">
                                    {data?.address ? <p>{data?.address}</p> : <p>{data?.customer?.address}</p>}
                                </div>) : null}

                        </div>
                    </div>

                    {/* <table className="w-full border-t border-[#abaaaa]" style={{ minHeight: vatAmount !== 0 ? 500 : 400 }} > */}
                    {vatAmount !== 0 ? (<table className="w-full border-t border-[#abaaaa] text-right" style={{ minHeight: 500 }}>
                        <thead className=" ">
                            <tr className="border-b border-[#abaaaa] ">
                                <th style={{ width: "5px" }} className="border-r border-[#abaaaa] text-center">S.No</th>
                                <th style={{ minWidth: "135px", maxWidth: "135px" }} className="border-r border-[#abaaaa]  text-left">Description </th>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <th style={{ minWidth: "70px", maxWidth: "70px" }} className="border-r border-[#abaaaa] text-center">HSN/SAC</th>
                                )}
                                {vatAmount !== 0 && <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">GST%</th>}
                                <th style={{ minWidth: "80px", maxWidth: "80px" }} className="border-r border-[#abaaaa] ">UOM</th>
                                <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">Qty</th>
                                <th style={{ minWidth: "80px", maxWidth: "80px" }} className="border-r border-[#abaaaa] ">Rate</th>
                                {data?.discount != 0 && <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">Dis%</th>}
                                <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-b border-[#abaaaa] ">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {data &&
                                _.map(filteredData, (d, i) => (
                                    <tr className={`${i + 1 < _.size(filteredData) ? "h-3 " : ""}`}>
                                        <td style={{ width: "5px" }} className="border-r border-[#abaaaa] align-top text-center" >{i + 1}</td>
                                        <td className=" align-top text-left p-0.5 font-bold flex justify-between" >
                                            <div>
                                                <div>{d.product.name}</div>{invoiceData?.show_description_pdf_invoice === true && (
                                                    <div className="text-sm font-normal">{d.description}</div>)}
                                            </div>
                                        </td>
                                        {invoiceData?.show_Hsn_Code === true && (
                                            <td style={{ width: "70px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.hsn_code}</td>
                                        )}
                                        {vatAmount !== 0 && <td style={{ width: "40px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5 ">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                        <td style={{ width: "38px" }} className="border-r border-[#abaaaa] align-top text-center p-0.5" >{d.product.unitType}</td>
                                        <td style={{ width: "35px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5" >{d.productQuantity}</td>
                                        <td style={{ width: "60px" }} className="border-r border-[#abaaaa] align-top p-0.5" >{d.productSalePrice}</td>
                                        {data?.discount != 0 && <td style={{ width: "50px" }} className="border-r border-[#abaaaa] align-top p-0.5" > {data?.discount_type === "amount" ? `₹${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}` : `${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}%`}</td>}
                                        <td style={{ width: "85px" }} className="align-top">{DECIMAL_NUMBER(d.productQuantity * d.productSalePrice, noOfDecimalDigits)}</td>
                                    </tr>
                                ))
                            }
                            {/* {data?.productQty < 5 && <div className="min-h-[150px]" />} */}

                            <tr className="h-6 border-t border-b border-[#abaaaa]">
                                <td className="" ></td>
                                <td className="pr-2" >TOTAL :  </td>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <td className=" " ></td>
                                )}
                                {vatAmount !== 0 && <td className="" ></td>}
                                <td className=" " ></td>
                                <td className="border-r border-[#abaaaa] text-right" >{data?.productQty}</td>
                                <td className="" ></td>
                                {data?.discount != 0 && <td className="" ></td>}
                                <td className="text-right " >₹{DECIMAL_NUMBER((data.grandTotal - vatAmount || 0), noOfDecimalDigits || 2)}</td>
                            </tr>

                        </tbody>
                    </table>) : (<table className="w-full border-t border-[#abaaaa]  text-right" style={{ minHeight: 600 }}>
                        <thead className=" ">
                            <tr className="border-b border-[#abaaaa] ">
                                <th style={{ width: "5px" }} className="border-r border-[#abaaaa] text-center">S.No</th>
                                <th style={{ minWidth: "135px", maxWidth: "135px" }} className="border-r border-[#abaaaa]  text-left">Description </th>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <th style={{ minWidth: "70px", maxWidth: "70px" }} className="border-r border-[#abaaaa] text-center">HSN/SAC</th>
                                )}
                                {vatAmount !== 0 && <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">GST%</th>}
                                <th style={{ minWidth: "80px", maxWidth: "80px" }} className="border-r border-[#abaaaa] ">UOM</th>
                                <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">Qty</th>
                                <th style={{ minWidth: "80px", maxWidth: "80px" }} className="border-r border-[#abaaaa] ">Rate</th>
                                {data?.discount != 0 && <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-r border-[#abaaaa] ">Dis%</th>}
                                <th style={{ minWidth: "60px", maxWidth: "60px" }} className="border-b border-[#abaaaa] ">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {data &&
                                _.map(filteredData, (d, i) => (
                                    <tr className={`${i + 1 < _.size(filteredData) ? "h-3 " : ""}`}>
                                        <td style={{ width: "5px" }} className="border-r border-[#abaaaa] align-top text-center" >{i + 1}</td>
                                        <td className=" align-top text-left p-0.5 font-bold flex justify-between" >
                                            <div>
                                                <div>{d.product.name}</div>{invoiceData?.show_description_pdf_invoice === true && (
                                                    <div className="text-sm font-normal">{d.description}</div>)}
                                            </div>
                                        </td>
                                        {invoiceData?.show_Hsn_Code === true && (
                                            <td style={{ width: "70px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.hsn_code}</td>
                                        )}
                                        {vatAmount !== 0 && <td style={{ width: "40px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5 text-center">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                        <td style={{ width: "38px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.product.unitType}</td>
                                        <td style={{ width: "35px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5" >{d.productQuantity}</td>
                                        <td style={{ width: "60px" }} className="border-r border-[#abaaaa] align-top p-0.5" >{d.productSalePrice}</td>
                                        {data?.discount != 0 && <td style={{ width: "50px" }} className="border-r border-[#abaaaa] align-top p-0.5" > {data?.discount_type === "amount" ? `₹${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}` : `${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}%`}</td>}
                                        <td style={{ width: "85px" }} className="align-top">{DECIMAL_NUMBER(d.productQuantity * d.productSalePrice, noOfDecimalDigits)}</td>
                                    </tr>
                                ))
                            }
                            {/* {data?.productQty < 5 && <div className="min-h-[150px]" />} */}


                            <tr className="h-6 border-t border-b border-[#abaaaa]">
                                <td className="" ></td>
                                <td className="pr-2" >TOTAL : </td>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <td className=" " ></td>
                                )}
                                {vatAmount !== 0 && <td className="" ></td>}
                                <td className=" " ></td>
                                <td className="border-r border-[#abaaaa] text-right" >{data?.productQty}</td>
                                <td className="" ></td>
                                <td className="border-r border-[#abaaaa] text-right" ></td>
                                <td className="text-right " >₹{DECIMAL_NUMBER((data.grandTotal || 0), noOfDecimalDigits)}</td>
                            </tr>
                        </tbody>
                    </table>)}
                    <div className="grid grid-cols-10 ">
                        <div className="col-span-7 mt-4">
                            {vatAmount !== 0 &&
                                <table className="w-full h-full border-t border-[#abaaaa]">
                                    <thead className="">
                                        <tr className="border-b border-[#abaaaa]">
                                            <th style={{ width: "150px" }} className="border-r border-[#abaaaa] text-center" colspan="4">{<p>GST%</p>}</th>
                                            {vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2">Taxable Value</th>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2"> Central Tax
                                                <div className="flex justify-between  border-t border-[#abaaaa]">
                                                    <div className="pl-4" colspan="1">Rate </div>
                                                    <div className="pr-4" colspan="1">Amount</div>
                                                </div></th>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2">State Tax
                                                <div className="flex justify-between border-t border-[#abaaaa]">
                                                    <div className="pl-4" colspan="1">Rate </div>
                                                    <div className="pr-4" colspan="1">Amount</div>
                                                </div></th>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="4">IGST
                                                <div className="flex justify-between border-t border-[#abaaaa]">
                                                    <div className="" colspan="2">Rate </div>
                                                    <div className="" colspan="2">Amount</div>
                                                </div></th>}
                                            <th className="text-center" colspan="2" >Total
                                                <div className="">Tax Amount </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            _.map(groupedGstData, (d) => (
                                                <tr className="border-b text-right">
                                                    <td style={{ width: "150px" }} className="text-center pl-0.5 border-r border-[#abaaaa]" colspan="4"> <div>{d.productGST}</div> </td>
                                                    {vatAmount !== 0 && <td className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER((d.taxableValue || 0), noOfDecimalDigits || 2)}</td>}
                                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{getProductGst(d) / 2}%</td>}
                                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((d.taxableValue * (getProductGst(d) / 100)) / 2, noOfDecimalDigits || 2)}</td>}
                                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits) / 2}%</td>}
                                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((d.taxableValue * (getProductGst(d) / 100)) / 2, noOfDecimalDigits || 2)}</td>}
                                                    {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                                    {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER(((((d.taxableValue * getProductGst(d)) / 100))), noOfDecimalDigits || 2)}</td>}
                                                    <td className=" " colspan="2">{DECIMAL_NUMBER(((((d.taxableValue * getProductGst(d)) / 100))), noOfDecimalDigits || 2)}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                    {vatAmount > 0 &&
                                        <tr className="text-right">
                                            <th style={{ width: "150px" }} className=" border-r border-[#abaaaa] " colspan="4">Total</th>
                                            <th className="text-center border-r border-[#abaaaa]" colspan="2" >{ }</th>
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="2">{(DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits || 2))}</th>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="2">{(DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits || 2))}</th>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="4">{DECIMAL_NUMBER(vatAmount, noOfDecimalDigits || 2)}</th>}
                                            <td className="font-bold " colspan="2">{DECIMAL_NUMBER(vatAmount, noOfDecimalDigits || 2)}</td>
                                        </tr>
                                    }
                                </table>
                            }
                        </div>

                        <div style={{ width: "" }} className="col-span-3 text-right border-l border-[#abaaaa]">
                            {data?.discount != 0 && <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">Discount:</div>
                                <div className="w-1/2"> {data?.discount_type === "amount" ? `₹${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}` : `${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}%`}</div>
                            </div>}
                            {data?.totalAmount != 0 && <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">Tot Amt Before Tax:</div>
                                <div className="w-1/2 align-middle">₹{DECIMAL_NUMBER((data.totalAmount - vatAmount || 0), noOfDecimalDigits || 2)}</div>
                            </div>}

                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">CGST:</div>
                                <div className="w-1/2"> ₹{DECIMAL_NUMBER((vatAmount / 2 || 0), noOfDecimalDigits || 2)}</div>
                            </div>}
                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">SGST:</div>
                                <div className="w-1/2">₹{DECIMAL_NUMBER((vatAmount / 2 || 0), noOfDecimalDigits || 2)}</div>
                            </div>}
                            {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">IGST:</div>
                                <div className="w-1/2">₹{DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits || 2)}</div>
                            </div>}
                            {vatAmount !== 0 && <div className="flex border-b border-[#abaaaa] ">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">Total Tax:</div>
                                <div className="w-1/2">₹{DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits || 2)}</div>
                            </div>}
                            <div className="flex border-b border-[#abaaaa]">
                                <div className="pr-1 border-r border-[#abaaaa] w-1/2">Round Off:</div>
                                <div className="w-1/2"></div>
                            </div>
                            <div className="flex">
                                <div className="pr-1 w-1/2">NET TOTAL:</div>
                                <div className="w-1/2">₹{DECIMAL_NUMBER((data.grandTotal || 0), noOfDecimalDigits || 2)}</div>
                            </div>
                        </div>

                    </div>
                    <div className="text-left  border-t border-[#abaaaa]">
                        {/* <h4 className="">Amount Chargeable (in words)</h4> */}
                        <h4 className="font-bold pl-2 mt-1">INR {totalInWords} Only</h4>
                    </div>

                    <div className="grid grid-cols-8 border-t border-[#abaaaa] px-2">
                        <div className="col-span-3 ">
                            <div className="">
                                <h4 className="underline underline-offset-2 text-left">Terms & Conditions :</h4>
                                {(invoiceData?.terms_conditions || data?.terms_conditions) &&
                                    <>
                                        <ol className="text-left text-sm list-inside">
                                            <li dangerouslySetInnerHTML={{ __html: data?.terms_conditions ? data?.terms_conditions : invoiceData?.terms_conditions }}></li>
                                        </ol>
                                    </>}
                            </div>
                            <div className=" font-semibold mt-12">
                                <h4 className="text-left">Customer's Seal and Signature</h4>
                            </div>
                        </div>
                        <div className="col-span-2 text-left pl-1">
                            {(invoiceData?.acc_holder_name || invoiceData?.acc_number || invoiceData?.acc_ifsc || invoiceData?.bank_name || invoiceData?.bank_branch) && <h4 className="underline underline-offset-2 font-semibold pr-3">Bank Details:</h4>}

                            <div className="">
                                {invoiceData?.bank_name && <div>BANK : {invoiceData?.bank_name}</div>}
                                {invoiceData?.bank_branch && <div>BRANCH : {invoiceData?.bank_branch}</div>}
                                {invoiceData?.acc_number && <div> A/C No. : {invoiceData?.acc_number}</div>}
                                {invoiceData?.acc_ifsc && <div>IFSC : {invoiceData?.acc_ifsc}</div>}
                            </div>
                        </div>
                        <div className="col-span-3 font-semibold">
                            <div className="text-right " >
                                <h4 className=" ">For <span className="font-bold">{invoiceData?.companyName}</span></h4>
                                <div className="flex justify-end h-18">{invoiceData?.signatureSnapshot && <img className="h-[70px]" src={invoiceData?.signatureSnapshot} />}</div>
                                <h4 className="">Authorised Signatory</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
});

const SaleInvoiceV3A4 = ({ data, vatAmount, styles = {}, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => successCallBack?.(),
    });

	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);

    return (
        <div>
            {/* <div className='' style={{ position: 'absolute', left: 100, right: 0, top: 1000, background: 'white' }}> */}
            <div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }}>
                <PrintToPdf
                    ref={componentRef}
                    data={data}
                    invoiceData={invoiceData}
                    vatAmount={vatAmount}
                    isQuotation={isQuotation}
                    productSettings={productSettings}
                />
            </div>
            {invoiceData && (
                <>
                    <Button style={styles} type='primary' shape='round' onClick={() => {
                        if (_.isFunction(preCall)) {
                            preCall(handlePrint);
                        } else {
                            handlePrint()
                        }
                    }}>
                        A4 Print5
                    </Button>
                </>
            )}
        </div>
    );
};

export default SaleInvoiceV3A4;
