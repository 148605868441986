// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddProdCat_importCsvCard__GTPZt {
  height: 100%;
}

.AddProdCat_addProdCatBtnContainer__DihpN {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/productCategory/AddProdCat.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".importCsvCard {\n  height: 100%;\n}\n\n.addProdCatBtnContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importCsvCard": `AddProdCat_importCsvCard__GTPZt`,
	"addProdCatBtnContainer": `AddProdCat_addProdCatBtnContainer__DihpN`
};
export default ___CSS_LOADER_EXPORT___;
