import { Button } from "antd";
import React, {
    forwardRef,
    Fragment,
    useRef,
} from "react";
import { useReactToPrint } from "react-to-print";
// import "./style.css";
import _ from "lodash"
import { DECIMAL_NUMBER, getMomentObj } from "../../utils/functions";
import converter from "number-to-words";
import { useSelector } from "react-redux";

// import { saveAs } from "file-saver";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {

    const word = converter.toWords(data?.totalAmount || 0);

    const totalInWords = word.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());
    const saleInvoiceProducts = _.reject(_.get(data, "saleInvoiceProduct", []), _.isEmpty)

    // const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
    // const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);

    const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

    const getProductGst = (product) => {
        return _.get(product, "productGST") || _.get(product, "vatPrice") || 0;
    }

    return (

        <Fragment>
            <div ref={ref} className=" w-[21cm] h-[29.7cm] mx-auto flex justify-center  bg-white">
                <div className=" p-3 w-[34.961rem] h-[49.606rem] ">
                    <div className="border-t border-r border-l border-[#abaaaa] mx-auto px-3 py-2 ">
                        <div className="text-center text-xl mb-1 font-bold">{invoiceData?.companyName}</div>
                        {/* {invoiceData?.gst_no && <div className="text-center text-base font-semibold mb-1">{invoiceData?.gst_no}</div>} */}
                        {invoiceData?.address && <div className="text-center text-base mb-1">{invoiceData?.address} </div>}
                        {invoiceData?.address && <div className="text-center text-base mb-1">{invoiceData?.address_l2 && invoiceData?.address_l2}</div>}
                        {invoiceData?.phone && <div className="text-center text-base mb-1">Phone No: {invoiceData?.phone}</div>}
                        {invoiceData?.email && <div className="text-center text-base mb-1">{invoiceData?.email}</div>}
                    </div>

                    <table className="w-full border border-[#abaaaa] mb-5 " style={{ minHeight: 900 }} >
                        <thead className="border-b border-[#abaaaa]">
                            <tr className="w-full" colSpan={8}>
                                <th className=" border-b border-[#abaaaa] text-left pl-2" rowspan="1" colSpan={6}>
                                    <h4 className="text-lg pl-1">Cust.Name :{data?.customer?.name}</h4>
                                    <h4 className="text-lg pl-16">Age :{data?.customer?.age}</h4>
                                    <h4 className="text-lg">Referred by :{data?.customer?.referBy}</h4>
                                    {/* <h4 className="text-lg font-medium">{data?.customer?.address}</h4> */}
                                </th>
                                <th style={{ width: "200px" }} className=" border-b border-[#abaaaa] text-right p-1" rowspan="1" colSpan={2}>
                                    <h4 className="text-lg">Date: {getMomentObj(data?.date).format("DD-MM-YYYY")}</h4>
                                    <h4 className="text-lg">Invoice No: {data?.manualId}</h4>

                                </th>
                            </tr>

                            <tr className="w-full" colSpan={8}>
                                <th style={{ width: "80px" }} className=" border-r border-b border-[#abaaaa] text-left p-2" colSpan={1}>S.No</th>
                                <th style={{ width: "400px" }} className=" border-r border-b border-[#abaaaa] text-left p-2" colSpan={6} >Test Name</th>
                                <th className=" border-r border-b border-[#abaaaa] text-left p-2" colSpan={1}>Amount</th>
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {
                                _.map(saleInvoiceProducts, (product, index) => (
                                    <tr className={`${index + 1 < _.size(saleInvoiceProducts) ? "h-5" : ""}`}>
                                        <td style={{ width: "80px" }} className="border-r border-[#abaaaa] align-top text-left p-1" colSpan={1}>{index + 1}</td>
                                        <td style={{ width: "400px" }} className="border-r border-[#abaaaa] align-top text-left p-1" colSpan={6}>{product?.product?.name}</td>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-1" colSpan={1}>{product?.productQuantity * product?.productSalePrice}</td>
                                    </tr>
                                ))
                            }
                            <tr className="h-8">
                                <td className="border-r border-b border-t border-[#abaaaa]  p-2" colSpan={8}>
                                    <div className="flex">
                                        <h4 className="text-base text-right w-4/6">Total Amount:</h4>
                                        <h4 className="text-base text-left w-2/6 pl-10"> {DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>

                                </td>
                            </tr>

                            <tr className="h-16">
                                <td className="border-r border-b border-[#abaaaa] text-left pl-2" colSpan={8} >
                                    <div className="">
                                        <h4 className="text-lg font-bold mt-1 ">Amount in words:</h4>
                                        <h4 className="mt-2 pl-4 text-lg font-medium">{totalInWords} only</h4>
                                    </div>
                                </td>

                            </tr>
                            <tr className="h-5">
                                <td className="  p-2 text-base font-semibold text-right" colSpan={8}>
                                    <h4 className="-mt-2">For <span className="text-lg">{invoiceData?.companyName}</span></h4>
                                    <div className="flex justify-end h-18">{invoiceData?.signatureSnapshot && <img className="h-[70px]" src={invoiceData?.signatureSnapshot} />}</div>
                                    <h4 className="-mb-1">Authorised Signatory</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>

    );
});

const SaleInvoiceA5 = ({ data, vatAmount, styles = {}, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => successCallBack?.(),
    });

	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);

    return (
        <div>
            {/* <div className='' style={{ position: 'absolute', left: 100, right: 0, top: 1000, background: 'white' }}> */}
            <div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }}>
                <PrintToPdf
                    ref={componentRef}
                    data={data}
                    invoiceData={invoiceData}
                    vatAmount={vatAmount}
                    isQuotation={isQuotation}
                    productSettings={productSettings}
                />
            </div>
            {invoiceData && (
                <>
                    <Button style={styles} type='primary' shape='round' onClick={() => {
                        if (_.isFunction(preCall)) {
                            preCall(handlePrint);
                        } else {
                            handlePrint()
                        }
                    }}>
                        A5 Print1
                    </Button>
                </>
            )}
        </div>
    );
};

export default SaleInvoiceA5;
