import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// import store from "./redux/root";
import store from "./redux/rtk/app/store";
const root = ReactDOM.createRoot(document.getElementById("root"));

//Setting AXIOS and token
// axios.defaults.baseURL = "https://oserpb.herokuapp.com/v1/";
axios.defaults.baseURL = process.env.REACT_APP_API;

// for setting up auth token in header
axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access-token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// for refreshing access token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        localStorage.setItem('access-token', "");
        const refreshToken = localStorage.getItem('refresh-token');
        const response = await axios.post('user/token/refresh', { token: refreshToken });
        if (response.status === 200) {
          localStorage.setItem('access-token', response.data.accessToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
          return axios(originalRequest);
        }
      } catch (err) {
        window.location.href = "/admin/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
