// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@page {
	/* size: 50mm 150mm;   */
	 /* A4 Size */
	/* size: 2.8in 11in; */
	margin-top: 0cm;
	margin-left: 0cm;
	margin-right: 0cm;
}

td.description,
th.description {
	width: 75px;
	max-width: 75px;
}

td.quantity,
th.quantity {
	width: 40px;
	max-width: 40px;
	word-break: break-all;
}

td.price,
th.price {
	width: 40px;
	max-width: 40px;
	word-break: break-all;
}

.centered {
	text-align: center;
	align-content: center;
}

.ticket {
	width: 155px;
	max-width: 155px;
}

img {
	max-width: inherit;
	width: inherit;
}

.wrapper2 {
	display: grid;
	grid-template-columns: 2fr 3fr 2fr;
	font-size: 15px;
	padding: 10px 0;

	margin: 0 auto;
	grid-auto-rows: minmax(70px, auto);
}

.wrapper2>div {
	padding: 0em;
}`, "",{"version":3,"sources":["webpack://./src/components/Invoice/posPrint1.css"],"names":[],"mappings":";AACA;CACC,wBAAwB;EACvB,YAAY;CACb,sBAAsB;CACtB,eAAe;CACf,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;;CAEC,WAAW;CACX,eAAe;AAChB;;AAEA;;CAEC,WAAW;CACX,eAAe;CACf,qBAAqB;AACtB;;AAEA;;CAEC,WAAW;CACX,eAAe;CACf,qBAAqB;AACtB;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;AACtB;;AAEA;CACC,YAAY;CACZ,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,aAAa;CACb,kCAAkC;CAClC,eAAe;CACf,eAAe;;CAEf,cAAc;CACd,kCAAkC;AACnC;;AAEA;CACC,YAAY;AACb","sourcesContent":["\n@page {\n\t/* size: 50mm 150mm;   */\n\t /* A4 Size */\n\t/* size: 2.8in 11in; */\n\tmargin-top: 0cm;\n\tmargin-left: 0cm;\n\tmargin-right: 0cm;\n}\n\ntd.description,\nth.description {\n\twidth: 75px;\n\tmax-width: 75px;\n}\n\ntd.quantity,\nth.quantity {\n\twidth: 40px;\n\tmax-width: 40px;\n\tword-break: break-all;\n}\n\ntd.price,\nth.price {\n\twidth: 40px;\n\tmax-width: 40px;\n\tword-break: break-all;\n}\n\n.centered {\n\ttext-align: center;\n\talign-content: center;\n}\n\n.ticket {\n\twidth: 155px;\n\tmax-width: 155px;\n}\n\nimg {\n\tmax-width: inherit;\n\twidth: inherit;\n}\n\n.wrapper2 {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 2fr;\n\tfont-size: 15px;\n\tpadding: 10px 0;\n\n\tmargin: 0 auto;\n\tgrid-auto-rows: minmax(70px, auto);\n}\n\n.wrapper2>div {\n\tpadding: 0em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
