import { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Button, Row, Col, Select } from "antd";
import { MODE_OF_PAYMENTS } from "../../utils/constants";
import { DeleteOutlined, ControlOutlined } from "@ant-design/icons"

const { Option } = Select;

const MOPModal = ({ form, openMOPsSplit, setOpenMOPsSplit, totalAmount = 0 }) => {

    useEffect(() => {
        form.setFieldValue("modeOfPayments", [
            {
                modeOfPayment: MODE_OF_PAYMENTS[0].value,
                amount: totalAmount
            }
        ])
    }, []);

    const handleSave = () => {
        setOpenMOPsSplit(false);
    }

    return (
        <Modal
            title='Split Payment'
            open={openMOPsSplit}
            onOk={() => { }}
            onCancel={() => { setOpenMOPsSplit(false) }}
            footer={false}
        >
            <>
                <Form.List name="modeOfPayments">
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                {fields.map(({ key, name, ...field }) => (
                                    <Row className='flex justify-between items-center' gutter={[10]} key={key}>
                                        <Col span={11}>
                                            <Form.Item
                                                {...field}
                                                style={{ marginBottom: "10px" }}
                                                fields={[{ name: "modeOfPayment" }]}
                                                name={[name, "modeOfPayment"]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder='Select mode of payment '
                                                    optionFilterProp='children'
                                                    className="mode-of-payment-dropdown"
                                                >
                                                    {
                                                        MODE_OF_PAYMENTS.map((item) => (
                                                            <Option key={item.value} value={item.value}>
                                                                {item.label}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>

                                            <Form.Item
                                                {...field}
                                                style={{ marginBottom: "10px" }}
                                                fields={[{ name: "amount" }]}
                                                name={[name, "amount"]}
                                                className="h-[34px"
                                            >
                                                <InputNumber />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item>
                                                <button
                                                    shape='circle'
                                                    className='flex justify-center h-[34px] w-[34px] items-center bg-red-600 text-white p-2 rounded-md mb-2'
                                                    onClick={() => {
                                                        remove(name);
                                                    }}
                                                >
                                                    <DeleteOutlined className='' />
                                                </button>
                                            </Form.Item></Col>
                                    </Row>
                                ))
                                }
                                <Form.Item style={{ marginBottom: "10px" }}>
                                    <Button onClick={() => add()} block type='secondary' htmlType='submit' shape='round'>
                                        + Add Payment
                                    </Button>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: "10px" }}>
                                    <Button onClick={handleSave} block type='primary' htmlType='submit' shape='round'>
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        )
                    }}
                </Form.List>
            </>
        </Modal>
    )
}

const MOPSelector = ({ form, totalAmount, dropdownWidth = "100%", initialValue = MODE_OF_PAYMENTS[0].value, labelStyle = {} }) => {
    const [selectedMOP, setSelectedMOP] = useState(MODE_OF_PAYMENTS[0].value);
    const [openMOPsSplit, setOpenMOPsSplit] = useState(false);

    const modeOfPaymentValue = form.getFieldValue("modeOfPayment");

    useEffect(() => {
        if (modeOfPaymentValue !== selectedMOP) {
            setSelectedMOP(modeOfPaymentValue);
        }
    }, [modeOfPaymentValue]);

    return (
        <>
            <MOPModal openMOPsSplit={openMOPsSplit} form={form} setOpenMOPsSplit={setOpenMOPsSplit} totalAmount={totalAmount} />
            <>
                <div className="flex items-center mr-2" style={labelStyle}>ModeOfPayment: </div>
                <div className="flex" style={{ width: dropdownWidth }}>
                    <Form.Item
                        name='modeOfPayment'
                        style={{ height: "34px", width: "100%" }}
                        rules={[
                            {
                                required: true,
                                message: "Mode of payment is required",
                            }
                        ]}
                        initialValue={initialValue}
                    >
                        <Select
                            showSearch
                            placeholder='Select mode of payment '
                            optionFilterProp='children'
                            onChange={(e) => setSelectedMOP(e)}
                        >
                            {
                                MODE_OF_PAYMENTS.map((item) => (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    {
                        selectedMOP == "Multiple" &&
                        <ControlOutlined onClick={() => setOpenMOPsSplit(true)} className="ml-2" style={{ fontSize: 22, cursor: "pointer" }} />
                    }
                </div>
            </>
        </>
    )
}

export default MOPSelector;