// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mr-top {

  margin-top: 40px;
  margin-bottom: 40px;
}

.mr-left {
  margin-left: 20px;
}

.example {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-label {}

.btnHeight {
  height: 35px !important;
}

.sale-additional-details {
  border: 0;
  background: none;
  padding: 0;
  margin-bottom: 20px;
}

.sale-additional-details .ant-collapse-item {
  border: 0;
}

.sale-additional-details .ant-collapse-content-box {
  padding: 0 !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-xl-24.ant-form-item-label{
  padding: 0 0 0 !important;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-drawer .ant-drawer-header {
  padding: 5px 20px  !important;
}`, "",{"version":3,"sources":["webpack://./src/components/sale/sale.css"],"names":[],"mappings":"AAAA;;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,sBAAsB;;AAEtB;EACE,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".mr-top {\n\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n\n.mr-left {\n  margin-left: 20px;\n}\n\n.example {\n  margin: 20px 0;\n  margin-bottom: 20px;\n  padding: 30px 50px;\n  text-align: center;\n  background: rgba(0, 0, 0, 0.05);\n  border-radius: 4px;\n}\n\n.ant-form-item {\n  margin-bottom: 0;\n}\n\n.ant-form-item-label {}\n\n.btnHeight {\n  height: 35px !important;\n}\n\n.sale-additional-details {\n  border: 0;\n  background: none;\n  padding: 0;\n  margin-bottom: 20px;\n}\n\n.sale-additional-details .ant-collapse-item {\n  border: 0;\n}\n\n.sale-additional-details .ant-collapse-content-box {\n  padding: 0 !important;\n}\n\n:where(.css-dev-only-do-not-override-1okl62o).ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-xl-24.ant-form-item-label{\n  padding: 0 0 0 !important;\n}\n:where(.css-dev-only-do-not-override-1okl62o).ant-drawer .ant-drawer-header {\n  padding: 5px 20px  !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
