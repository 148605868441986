import axios from "axios";

const getSetting = async () => {
  try {
    const res = await axios.get(`setting`)
    
    return {
      result: res.data,
      message: "success",
    };
    // return data;
  } catch (error) {
    console.log(error.message);
    return {}
  }
}

export const getProductSetting = async () => {
  try {
    const res = await axios.get(`product-setting`)
    
    return {
      result: res.data,
      message: "success",
    };
    // return data;
  } catch (error) {
    console.log(error.message);
    return {}
  }
}

export const getCustomerSetting = async () => {
  try {
    const res = await axios.get(`setting/customer-setting`)

    return {
      result: res.data,
      message: "success",
    };
    // return data;
  } catch (error) {
    console.log(error.message);
    return {}
  }
}

export const getTemplateSetting = async () => {
  try {
    const res = await axios.get(`setting/template-setting`)

    return {
      result: res.data,
      message: "success",
    };
    // return data;
  } catch (error) {
    console.log(error.message);
    return {}
  }
}

export default getSetting;