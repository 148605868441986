import { Button } from "antd";
import React, {
	forwardRef,
	Fragment,
	useRef,
} from "react";
import { useReactToPrint } from "react-to-print";
import "./posPrint.css";
import _ from "lodash";
import { DECIMAL_NUMBER, getMomentObj, isValidUrl } from "../../utils/functions";
import { useSelector } from "react-redux";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {
	const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
	const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);
	const showMrp = invoiceData?.show_invoice_mrp;
	const showSaved = invoiceData?.show_invoice_saved;
	const productData = isQuotation ? data.quotationProduct : data.saleInvoiceProduct;
	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

	const getDiscount = () => {
		let totalDiscount = _.reduce(productData, (discount, product) => {
			const productMrpPrice = _.get(product, "product.productMrpPrice", 0);
			const productSalePrice = _.get(product, "productSalePrice", 0);
			const productQuantity = _.get(product, "productQuantity", 0);
			if (productSalePrice && productMrpPrice) {
				return discount + ((productMrpPrice - productSalePrice) * productQuantity);
			}
			return discount
		}, 0);
		const totalAmount = _.get(data, "totalAmount");
		const grandTotal = _.get(data, "grandTotal");
		if (totalAmount && grandTotal)
			totalDiscount = totalDiscount + totalAmount - grandTotal;
		return totalDiscount;
	}
	const totalDiscount = DECIMAL_NUMBER(getDiscount(), noOfDecimalDigits);
	return (
		<Fragment>
			<div ref={ref} className=''>
				<div className='pos-print-body w-[240px] m-auto'>

					<div className='bill'>
						<div className=''>
							{isValidUrl(invoiceData?.companyLogo) && <img className="min-w-[50px] max-w-[150px] min-h-[50px] max-h-[80px] object-contain m-auto" src={invoiceData?.companyLogo}></img>}
							<h3 className='font-bold uppercase !text-xl'>{invoiceData?.companyName}</h3>
							{invoiceData?.tagLine && <div className='tagline'>{invoiceData?.tagLine}</div>}
							{invoiceData?.gst_no && <div className="text-center text-base font-semibold mb-1">{invoiceData?.gst_no}</div>}
							<div className='address'>
								{invoiceData?.address && <span>{invoiceData?.address}</span>} <br />
								{invoiceData?.address_l2 && <span>{invoiceData?.address_l2}</span>} <br />
								{invoiceData?.phone && <span>{invoiceData?.phone}</span>}
							</div>
							{invoiceData?.email && <div className='email'>{invoiceData?.email}</div>}
							{invoiceData?.website && <div className='website'>{invoiceData?.website} </div>}
							<div className='flex justify-between capitalize mt-2'>

								<div className='text-left'>
									{data?.customer?.name && <div>{data?.customer?.name}</div>}
									{data?.customer?.phone && <div>{data?.customer?.phone}</div>}
								</div>

								<div className='text-left'>
									<div><b>{isQuotation ? "Quote" : "Bill"} No:</b> {data?.invoicePrefix}{data?.manualId}</div>
									{invoiceData?.show_table_number && data?.tableNo && (<div><b>Table No:</b> {data?.tableNo}</div>)}
									<div className="text-sm"> {getMomentObj(data?.date).format("DD-MM-YYYY")} <b>/</b> {invoiceData?.Show_time_invoice === true && (<span className="text-sm">{getMomentObj(data?.time).format("HH:mm:ss")}</span>)}</div>
								</div>
							</div>
						</div>
					</div>

					<table className='border-b-2 border-dashed  border-[#000] py-4'>

						<tr className='header border-t-2 border-b-2 border-dashed py-2 border-[#000] mb-5'>
							<th>SR</th>
							<th>Name</th>
							<th>Qty</th>
							{showMrp ? <th>MRP</th> : null}
							<th>Price</th>
							<th>Amt</th>
						</tr>

						{data &&
							_.filter(productData, (each) => each).map((d, i) => (
								<tr key={d.id} className='data'>
									<td>{i + 1}</td>
									<td className="text-[13px] text-left">{d.product?.name}</td>
									<td className="text-center">{d.productQuantity}</td>
									{showMrp ? <td className="text-center">{d.productMrpPrice || d.product?.productMrpPrice}</td> : null}
									<td className="text-center">{d.productSalePrice}</td>
									<td className="text-center">{DECIMAL_NUMBER(d.productQuantity * d.productSalePrice, noOfDecimalDigits)}</td>
								</tr>
							))}
					</table>

					<div className=" mt-2 w-full px-2">
						{/* <div className='flex justify-end w-full'>
							<td>Total Items</td>
							<td>
								{data.saleInvoiceProduct?.reduce( (totalQty, item) => totalQty + item.productQuantity, 0)}
							</td>
						</div>

						<div className='flex justify-between'>
							<td>Total Amount</td>
							<td>{data.totalAmount - vatAmount}</td>
						</div> */}

						{storeGSTCode === customerGSTCode || !customerGSTCode ? <div>
							{vatAmount !== 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left text-sm">CGST</span>
								<span className="w-full text-right text-sm">₹{DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits)}</span>
							</div>}

							{vatAmount !== 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left text-sm">SGST</span>
								<span className="w-full text-right text-sm">₹{DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits)}</span>
							</div>}
						</div> :
							<div>
								{vatAmount !== 0 && <div className='flex justify-end w-full'>
									<span className="w-full text-left text-sm">IGST</span>
									<span className="w-full text-right text-sm">₹{vatAmount}</span>
								</div>}
							</div>}

						{/* {data?.productDiscount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left">Discount</span>
							<span className="w-full text-right">₹{data?.productDiscount}</span>
						</div>} */}

						{data?.totalAmount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left text-sm">Sub Total</span>
							<span className="w-full  text-right text-sm">₹{data.totalAmount}</span>
						</div>}

						{data?.returnTotal > 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left">Return Total</span>
							<span className="w-full  text-right">- ₹{data.returnTotal}</span>
						</div>}

						{data?.discount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left text-sm">Discount(-)</span>
							<span className="w-full  text-right text-sm">{data?.discount_type == "amount" ? "₹" : " "}{data?.discount}{data?.discount_type == "percentage" ? "%" : " "} </span>
						</div>}

						<div className='w-full border-t border-dashed mt-2 py-2 border-[#000]'>
							<div className="flex justify-end w-full">
								<span className="w-full text-left ">Grand Total</span>
								<span className="text-right ml-5 font-bold">{data?.productQty}</span>
								<span className="w-full  text-right font-bold">₹{data?.grandTotal}</span>
							</div>
							<div className="flex justify-center w-full mt-3">
								{showSaved && totalDiscount ? <>
									<span style={{ fontSize: 14 }} className="w-full ">** You Saved</span>
									<span style={{ fontSize: 14 }} className=""><pre> - </pre></span>
									<span style={{ fontSize: 14 }} className="w-full">₹{totalDiscount} **</span>
								</> : null}
							</div>
						</div>
					</div>



					{invoiceData?.footer}
				</div>
			</div>
		</Fragment>
	);
});

const PosPrint = ({ data, vatAmount, styles, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => successCallBack?.(),
	});
	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);

	return (
		<div>
			<div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }}>
				<PrintToPdf
					ref={componentRef}
					data={data}
					invoiceData={invoiceData}
					productSettings={productSettings}
					vatAmount={vatAmount}
					isQuotation={isQuotation}
				/>
			</div>
			{invoiceData && (
				<Button style={styles} type='primary' shape='round' onClick={() => {
					if (_.isFunction(preCall)) {
						preCall(handlePrint);
					} else {
						handlePrint()
					}
				}}>
					3" POS1
				</Button>
			)}
		</div>
	);
};

export default PosPrint;
