// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inclusive-gst-container .ant-form-item-row {
    display: flex;
    flex-direction: row !important;
}

.inclusive-gst-container .ant-form-item-label {
    padding: 0 0;
    display: flex;
}

.inclusive-gst-container .ant-form-item-control {
    width: -moz-fit-content !important;
    width: fit-content !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/product/addProd.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kCAA6B;IAA7B,6BAA6B;AACjC","sourcesContent":[".inclusive-gst-container .ant-form-item-row {\n    display: flex;\n    flex-direction: row !important;\n}\n\n.inclusive-gst-container .ant-form-item-label {\n    padding: 0 0;\n    display: flex;\n}\n\n.inclusive-gst-container .ant-form-item-control {\n    width: fit-content !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
