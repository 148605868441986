import { toast } from "react-toastify";
import { getBlobFromHtml } from "../../utils/functions";
import axios from "axios";

	// for handling border collapse issue while converting to pdf using html2canvas
const toggleTranslateCssToTableHeader = (invoiceContainerRef) => {
    if (invoiceContainerRef?.current) {
        const specificElement = invoiceContainerRef.current.querySelector('table .header');
        if (specificElement?.children) {
            Array.from(specificElement.children).forEach(child => {
                child.classList.toggle('-translate-y-1');
            });
        }
    }
}

export const handleSendInvoice = async (invoiceId, invoiceContainerRef) => {
    if (invoiceContainerRef?.current) {
        toggleTranslateCssToTableHeader(invoiceContainerRef)
        const blob = await getBlobFromHtml(invoiceContainerRef)
        toggleTranslateCssToTableHeader(invoiceContainerRef)
        // Send PDF blob to backend
        const formData = new FormData();
        formData.append('invoice', blob);
        try {
            const response = await axios.post(`sale-invoice/send-invoice/${invoiceId}`, formData);
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    } else {
        toast.error("Template is not rendered");
    }
}