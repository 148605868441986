// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddProdSubcat_importCsvCard__4Ep1c {
  height: 100%;
}

.AddProdSubcat_addProdSubCatBtnContainer__73t0R {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductSubcategory/AddProdSubcat.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".importCsvCard {\n  height: 100%;\n}\n\n.addProdSubCatBtnContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importCsvCard": `AddProdSubcat_importCsvCard__4Ep1c`,
	"addProdSubCatBtnContainer": `AddProdSubcat_addProdSubCatBtnContainer__73t0R`
};
export default ___CSS_LOADER_EXPORT___;
