import { Spin } from "antd";
import React from "react";

const Spinner = (props) => {
    return (
        <div style={{ position: 'fixed', height: '80vh', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
            <Spin></Spin>
        </div>
    )
}

export default Spinner;