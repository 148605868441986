export const MODE_OF_PAYMENTS = [
    { value: "CASH", label: "CASH", },
    { value: "UPI", label: "UPI", },
    { value: "IMPS", label: "IMPS", },
    { value: "SWIPING", label: "Swiping", },
    { value: "Others", label: "OTHERS", },
    { value: "Multiple", label: "Multiple" }
]

export const DEFAULT_IMAGE = "/images/default.jpg";

// Quill modules to add features like toolbar, image upload, etc.
export const textEditorModule = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        ["link", "image"],
        [{ color: [] }, { background: [] }],
        ["clean"],
    ],
};

// Quill formats to specify allowed styles
export const textEditorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
];

export const PERSISTENT = "persistent";

export const PERMISSIONS = {
    CREATE_PURCHASEINVOICE: "create-purchaseInvoice",
    READALL_PURCHASEINVOICE: "readAll-purchaseInvoice",
    READSINGLE_PURCHASEINVOICE: "readSingle-purchaseInvoice",
    UPDATE_PURCHASEINVOICE: "update-purchaseInvoice",
    DELETE_PURCHASEINVOICE: "delete-purchaseInvoice",
    READALL_SUPPLIER: "readAll-supplier",
    CREATE_SUPPLIER: "create-supplier",
    READSINGLE_SUPPLIER: "readSingle-supplier",
    UPDATE_SUPPLIER: "update-supplier",
    DELETE_SUPPLIER: "delete-supplier",
}