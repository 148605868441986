import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const SerialNoModal = ({ openModal, setOpenModal, handleSetSerialNos, serialNosInitialValue }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState();

    useEffect(() => {
        let initialValue = _.map(serialNosInitialValue, each => ({
            serialNo: each
        }))
        initialValue = _.isEmpty(initialValue) ? [{}] : initialValue;
        form.setFieldValue("serialNos", initialValue);
    }, [serialNosInitialValue]);

    const handleSave = async () => {
        const serialNos = _.map(form.getFieldValue("serialNos"), 'serialNo');
        setLoading(true);
        await handleSetSerialNos(serialNos);
        setLoading(false);
    }

    return (
        <>
            <Modal
                title="Serial No's"
                open={openModal}
                onOk={() => { }}
                onCancel={() => { setOpenModal(false) }}
                footer={false}
            >
                <>
                    <Form initialValues={
                        {
                            "serialNos": [{}]
                        }
                    } onFinish={handleSave} form={form}>
                        <Form.List name="serialNos">
                            {(fields, { add, remove }) => {
                                return (
                                    <div>
                                        {fields.map(({ key, name, ...field }) => (
                                            <Row className='flex justify-between items-center' gutter={[10]} key={key}>
                                                <Col span={22}>
                                                    <Form.Item
                                                        {...field}
                                                        style={{ marginBottom: "10px" }}
                                                        fields={[{ name: "serialNo" }]}
                                                        name={[name, "serialNo"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter serial no!",
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <button
                                                            shape='circle'
                                                            className='flex justify-center h-[34px] w-[34px] items-center bg-red-600 text-white p-2 rounded-md mb-2'
                                                            onClick={() => {
                                                                remove(name);
                                                            }}
                                                        >
                                                            <DeleteOutlined className='' />
                                                        </button>
                                                    </Form.Item></Col>
                                            </Row>
                                        ))
                                        }
                                        <Form.Item style={{ marginBottom: "10px" }}>
                                            <Button onClick={() => add()} block type='secondary' htmlType='submit' shape='round'>
                                                + Add
                                            </Button>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: "10px" }}>
                                            <Button loading={loading} block type='primary' htmlType='submit' shape='round'>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </div>
                                )
                            }}
                        </Form.List>
                    </Form>
                </>
            </Modal>
        </>
    )
}

export default SerialNoModal;