import { Select, Tag } from "antd";
import React, { useEffect } from "react";
import { useLocalStorage } from "../../utils/hooks";
import { PERSISTENT } from "../../utils/constants";

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};

const ColVisibilityDropdown = ({ options, columns, columnsToShowHandler, localKey }) => {
  const [defaultValue, setDefaultValue] = useLocalStorage(
    `${PERSISTENT}-${localKey}`,
    options.map((option) => {
      return option.title;
    })
  );
  const modOptions = options.map((option) => {
    return {
      id: option.id,
      value: option.title,
    };
  });

  useEffect(() => {
    setDefaultValue(defaultValue);
  }, [JSON.stringify(options)]);

  useEffect(() => {
    const columnsToShow = columns.filter((column) => {
      const colFound = defaultValue.find((selectedCol) => column.title === selectedCol);
      return colFound;
    });
    columnsToShowHandler(columnsToShow);
  }, [JSON.stringify(defaultValue)]);

  const handleChange = (selectedCols) => {
    setDefaultValue(selectedCols);
  };

  return (
    <Select
      mode="multiple"
      showArrow
      tagRender={tagRender}
      value={defaultValue}
      style={{
        width: "180px",
      }}
      maxTagCount={0}
      options={modOptions}
      maxTagPlaceholder="Column Selected"
      placeholder="Column Visibility"
      onChange={handleChange}
    />
  );
};
export default ColVisibilityDropdown;
