import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, Select, Space, Upload } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import axios from "axios";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../redux/rtk/features/product/productSlice";
import { loadAllProductCategory } from "../../redux/rtk/features/productCategory/productCategorySlice";
import { loadAllProductSubCategory } from "../../redux/rtk/features/productSubCategory/productSubCategorySlice";

import styles from "./AddProd.module.css";
import "./addProd.css"

import BigDrawer from "../Drawer/BigDrawer";
import AddProductCategory from "../ProductSubcategory/addProductSubcategory";
import { loadAllVatTax } from "../../redux/rtk/features/vatTax/vatTaxSlice";
import { DECIMAL_NUMBER } from "../../utils/functions";
import _ from "lodash";
import { useLocalStorage } from "../../utils/hooks";
import { PERSISTENT } from "../../utils/constants";

const AddProd = ({ onSuccess, hideSaveAndCreate = false }) => {
	const { Option } = Select;
	const unitType = ["KG", "LTR", "PCs", "NOS", "Box", "DOZ", "MTR", "TON", "UNT", "Gm", "Bags", "Sqft", "ft", "BDL"];

	const { list: vatTaxList, loading: vatTaxLoading } = useSelector((state) => state.vatTax);
	const category = useSelector((state) => state.productCategories?.list);
	const subCategory = useSelector((state) => state.productSubCategories?.list);
	const brand = useSelector((state) => state.productBrands?.list);

	const dispatch = useDispatch();
	//useEffect for loading category list from redux
	useEffect(() => {
		dispatch(loadAllProductCategory({ page: 1, count: 100, status: true }));
		dispatch(loadAllProductSubCategory({ page: 1, count: 100, status: true }));
		dispatch(loadAllVatTax());
		// dispatch(loadAllProductBrand({ page: 1, count: 100, status: true }));
		// dispatch(loadColorPaginated({ count: 50 }));
	}, [dispatch]);

	const [thumbFileList, setThumbFileList] = useState([]);
	const [thumbFileListURL, setThumbFileListURL] = useState([]);
	const [loader, setLoader] = useState(false);

	const [prodSubCat, setProdSubCat] = useState(null);
	const [prodBrand, setProdBrand] = useState(null);
	const [prodDescription, setProdDescription] = useState("");

	// Quill modules to add features like toolbar, image upload, etc.
	const textEditorModule = {
		toolbar: [
			[{ header: "1" }, { header: "2" }, { font: [] }],
			[{ list: "ordered" }, { list: "bullet" }],
			["bold", "italic", "underline"],
			["link", "image"],
			[{ color: [] }, { background: [] }],
			["clean"],
		],
	};

	// Quill formats to specify allowed styles
	const textEditorFormats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"color",
		"background",
	];

	const prodSubCatHandler = (val) => {
		setProdSubCat(val);
	};

	const prodBrandHandler = (val) => {
		setProdBrand(val);
	};

	const prodDescriptionHandler = (e) => {
        setProdDescription(e.target.value);
	};

	const [selectedColors, setSelectedColors] = useState([]);

	const colorsHandler = (val) => {
		setSelectedColors(val);
	};

	const [form] = Form.useForm();
	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);

	const [isFrom, setIsFrom] = useState("");
	const [inclusiveGSTDefault, setInclusiveGSTDefault] = useLocalStorage(`${PERSISTENT}-inclusiveGSTDefault`, false);
	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);


	const onFinish = async (values) => {
		try {
			let formData = new FormData();

			formData.append("colors", selectedColors);
			if (thumbFileListURL?.length) {
				formData.append("images", thumbFileListURL[0]);
			}
			formData.append("name", values.name);
			formData.append("productSubCategoryId", prodSubCat);
			formData.append("productBrandId", prodBrand);
			formData.append("sku", values.sku || "");
			formData.append("hsn_code", values.hsn_code || "");
			formData.append("unitType", values.unitType);
			formData.append("productPurchasePrice", values.productPurchasePrice ?? 0);
			formData.append("productSalePrice", values.productSalePrice ?? 0);
			formData.append("productQuantity", values.productQuantity ?? 0);
			formData.append("description", prodDescription);
			formData.append("discount", values?.discount ?? 0);
			formData.append("discount_type", values?.discount_type ?? "amount");
			formData.append("productMrpPrice", values?.productMrpPrice ?? 0);
			formData.append("productVat", parseInt(values.productVat ?? 0));
			formData.append("skuPrefix", invoiceData?.companyName ? invoiceData?.companyName.substr(0, 2).toUpperCase() : "SKU");
			// formData.append("unitMeasurement", values.unitMeasurement ?? undefined);
			// formData.append("reorderQuantity", values.reorderQuantity ?? 0);
			formData.append("inclusiveGst", values.inclusiveGst);
			const resp = await dispatch(addProduct(formData));

			if (resp.payload.message === "success" && isFrom !== "saveAndClose") {
				//resting form fields after successful product creation
				form.resetFields();
				setThumbFileList([]);
				setThumbFileListURL([]);

				setSelectedColors([]);
				setProdDescription("");

				setThumbFileList([]);
				setThumbFileListURL([]);

				setLoader(false);

				// window.location.reload();
			} else if (resp.payload.message === "success" && isFrom === "saveAndClose") {
				//resting form fields after successful product creation
				form.resetFields();
				setThumbFileList([]);

				setThumbFileListURL([]);

				setSelectedColors([]);
				setProdDescription("");

				setThumbFileList([]);
				setThumbFileListURL([]);

				setLoader(false);

				if (onSuccess) {
					onSuccess();
				} else {
					setTimeout(() => window.location.reload(), 1000);
				}
		  
			} else {
				setLoader(false);
				toast.error(resp.payload?.data?.message || "error at creating");
			}
		} catch (error) {
			console.log(error.message);
			toast.error("error at creating");
			setLoader(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		setLoader(false);
		toast.error("Something went wrong !");
		console.log("Failed:", errorInfo);
	};

	const handelThumbImageChange = ({ fileList }) => {
		const formdata = new FormData();
		formdata.append("file", fileList[0].originFileObj);
		formdata.append("keyname", "product");

		let config = {
			method: 'post',
			url: `${process.env.REACT_APP_API}file/upload`,
			headers: {
			},
			data: formdata
		};
		
		axios.request(config)
		.then((response) => {
			setThumbFileList([fileList[0]]);
			setThumbFileListURL([response.data.result]);
		})
		.catch((error) => {
		  console.log(error);
		});
		
	};

	// const validateDiscount = (_, value) => {
	// 	if (value >= 0 && value <= total) {
	// 		return Promise.resolve();
	// 	}
	// 	return Promise.reject(`Discount must be greater than or equal to ${total}`);
	// };

	const updateMRP = () => {
		const salePrice = Number(form.getFieldValue("productSalePrice"));
		const discount_type = form.getFieldValue("discount_type");
		const discount = Number(form.getFieldValue("discount"));
		if (discount_type == "amount") {
			// update MRP for a discount amount
			form.setFieldValue("productMrpPrice", DECIMAL_NUMBER(salePrice + discount, noOfDecimalDigits));
		} else {
			// update MRP for a discount percentage
			form.setFieldValue("productMrpPrice", DECIMAL_NUMBER(salePrice / ((100 - discount) / 100), noOfDecimalDigits));
		}
	}

	const updateSalePrice = () => {
		const productMrpPrice = Number(form.getFieldValue("productMrpPrice"));
		const discount_type = form.getFieldValue("discount_type");
		const discount = Number(form.getFieldValue("discount"));

		if (discount_type == "amount") {
			// update sale price for a discount amount
			form.setFieldValue("productSalePrice", DECIMAL_NUMBER(productMrpPrice - discount, noOfDecimalDigits));
		} else {
			// update sale price for a discount percentage
			form.setFieldValue("productSalePrice", DECIMAL_NUMBER(productMrpPrice - ((productMrpPrice * discount) / 100), noOfDecimalDigits));
		}
	}
	
	return (
		<>
			<Form
				form={form}
				name='basic'
				className='max-md:mx-1'
				layout='vertical'
				initialValues={{
					remember: true,
					discount: 0,
					discount_type: "amount",
					productMrpPrice: 0,
					productSalePrice: 0,
					productPurchasePrice: 0,
					inclusiveGst: inclusiveGSTDefault
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete='off'
				style={{ marginLeft: "40px", marginRight: "40px" }}>

			 <div className="sm:flex gap-5">
				<Form.Item
				    className=""
					style={{ marginBottom: "3px", width: "85%" }}
					label='Name'
					name='name'
					rules={[
						{
							required: true,
							message: "Please input Product name!",
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
				    className=""
					style={{ marginBottom: "3px", width: "85%" }}
					name='productSubCategoryId'
					label='Select Subcategory'
					rules={[
						{
							// required: true,
							message: "Please select sub-category!",
						},
					]}>
					<Space.Compact block>
						<Select
							name='productSubCategoryId'
							loading={!subCategory}
							showSearch
							placeholder='Select Subcategory'
							optionFilterProp='children'
							// filterOption={(input, option) => option.children.includes(input)}
							filterSort={(optionA, optionB) =>
								optionA.children
									.toLowerCase()
									.localeCompare(optionB.children.toLowerCase())
							}
							onChange={prodSubCatHandler}>
							{subCategory &&
								subCategory.map((subcat) => (
									<Select.Option key={subcat.id} value={subcat.id}>
										{subcat.name}
									</Select.Option>
								))}
						</Select>
						<BigDrawer
						    className="max-lg:w-10 lg:w-14"
							btnTitle={""}
							title='new Sub-Category'
							children={<AddProductCategory drawer={true} />}
						/>
					</Space.Compact>
				</Form.Item>
				</div>

				{/* <Form.Item
					style={{ marginBottom: "15px" }}
					name='productBrandId'
					label='Select Brand'
					rules={[
						{
							required: true,
							message: "Please select brand!",
						},
					]}>
					<Space.Compact block>
						<Select
							name='productBrandId'
							loading={!brand}
							showSearch
							placeholder='Select Brand'
							optionFilterProp='children'
							filterOption={(input, option) => option.children.includes(input)}
							filterSort={(optionA, optionB) =>
								optionA.children
									.toLowerCase()
									.localeCompare(optionB.children.toLowerCase())
							}
							onChange={prodBrandHandler}>
							{brand &&
								brand.map((brandSingle) => (
									<Select.Option key={brandSingle.id} value={brandSingle.id}>
										{brandSingle.name}
									</Select.Option>
								))}
						</Select>
						<BigDrawer
							btnTitle={"Brand"}
							title='new Brand'
							children={<AddProductBrand drawer={true} />}
						/>
					</Space.Compact>
				</Form.Item> */}
				<div className="flex justify-between gap-5">
					<Form.Item
						style={{ marginBottom: "3px", width: "100%" }}
						label='Purchase Price'
						name='productPurchasePrice' 
						rules={[
							{
								required: false,
								message: "Please input Purchase Price!",
							},
						]}>
						<InputNumber
							type="number"
							size={"small"}
							min={0}
							defaultValue={0}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: "3px", width: "100%" }}
						label='Sale Price'
						name='productSalePrice'
						rules={[
							{
								required: true,
								message: "Please input Sale Price!",
							},
						]}>
						<InputNumber
							type="number"
							size={"small"}
							min={0}
							defaultValue={0}
							onChange={updateMRP}
						/>
					</Form.Item>

				</div>
				{/* <Form.Item
					style={{ marginBottom: "15px" }}
					label='Unit Measurement'
					name='unitMeasurement'
					rules={[
						{
							required: true,
							message: "Please input Unit Measurement!",
						},
					]}>
					<Input type='number' />
				</Form.Item> */}


				<div className="flex justify-between gap-5">
					<Form.Item className='!mb-1 !w-full discount_type' label="Discount Type" name='discount_type'>
						<Select
							className='!w-full  !flex !items-center'
							placeholder='Select Discount type'
							maxTagCount={5}
							showArrow
							size={"small"}
							onChange={updateMRP}
							defaultValue={"amount"}
						// onChange={() => totalCalculator()}
						>
							{["percentage", "amount"]?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						className='mb-1 w-full'
						name='discount'
						label='Discount'
						rules={[
							{
								required: true,
								message: "Please input Discount!",
							},
						]}>
						<InputNumber
							className=''
							type="number"
							size={"small"}
							placeholder="Discount (₹ / %)"
							// onChange={totalCalculator}
							min={0}
							defaultValue={0}
							onChange={updateMRP}
						/>
					</Form.Item>	
					
				</div>

				<div className="flex gap-5">
					<Form.Item
						className="!w-full"
						style={{ marginBottom: "3px" }}
						label='MRP'
						name='productMrpPrice'
						rules={[
							{
								required: false,
								message: "Please input MRP!",
							},
						]}>
						<InputNumber
							type="number"
							size={"small"}
							min={0}
							defaultValue={0}
							onChange={updateSalePrice}
						/>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: "3px", width: "100%" }}
						name='unitType'
						label='Select Unit Type '
						initialValue={"NOS"}
						rules={[
							{
								required: true,
								message: "Please select unit type!",
							},
						]}>
						<Select
							name='unitType'
							loading={!category}
							showSearch
							placeholder='Select Unit Type'
							optionFilterProp='children'
							filterOption={(input, option) => option.children.includes(input.toUpperCase())}
							filterSort={(optionA, optionB) =>
								optionA.children
									.toLowerCase()
									.localeCompare(optionB.children.toLowerCase())
							}>
							{unitType &&
								unitType.map((unit) => (
									<Select.Option key={unit} value={unit}>
										{unit}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
					
				</div>
				{/* <Form.Item
					style={{ marginBottom: "15px" }}
					label='Reorder Quantity'
					name='reorderQuantity'
					rules={[
						{
							required: false,
							message: "Please input  Reorder Quantity!",
						},
					]}>
					<Input type='number' />
				</Form.Item> */}

				{/* {color?.length && (
					<Form.Item
						style={{ marginBottom: "15px" }}
						label='Colors'
						rules={[
							selectedColors?.length === 0 && {
								required: true,
								message: "Please input Color!",
							},
						]}>
						<ColorDropdown
							data={color}
							selectedColors={selectedColors}
							colorsHandler={colorsHandler}
						/>
					</Form.Item>
				)} */}

				<div className="flex justify-between gap-5">
					<div className="w-full">
						<Form.Item
							style={{ marginBottom: "3px", width: "100%" }}
							label='Quantity'
							name='productQuantity'
							normalize={(value) => (!value ? 0 : value)}
							rules={[
								{
									required: false,
									message: "Please input Quantity!",
								},
							]}>
							<Input type='number' value={0} />
						</Form.Item>
						<Form.Item
							style={{ marginBottom: "", width: "100%" }}
							label='Upload Thumbnail Image'
							valuePropName='thumbnail_image'
							required={false}>
							<Upload
								listType='picture-card'
								beforeUpload={() => false}
								name='image'
								fileList={thumbFileList}
								maxCount={1}
								onChange={handelThumbImageChange}>
								<div>
									<PlusOutlined />
									<div
										style={{
											marginTop: 4,
										}}>
										Upload
									</div>
								</div>
							</Upload>
						</Form.Item>
					</div>

					<div className="w-full">
						<Form.Item
							style={{ marginBottom: "1px" }}
							label='GST'
							name='productVat'>
							{/* <Input type='number' placeholder='Enter GSt percentage' defaultValue={0} /> */}
							<Select
								name='productVat'
								loading={vatTaxLoading}
								showSearch
								placeholder='Select GST'
								optionFilterProp='children'
								defaultActiveFirstOption={true}
								>
								{vatTaxList?.map((item) => (
									<Option key={item.id} value={item.id}>
										{item.title}
										<span className='italic'>@{item.percentage}%</span>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Inclusive GST"
							name="inclusiveGst"
							className="inclusive-gst-container"
							style={{ marginBottom: "1px" }}
							valuePropName="checked"
						>
							<Checkbox name="inclusiveGst" className={styles.InclusiveGSTItem} onChange={(e) => setInclusiveGSTDefault(e.target.checked)}></Checkbox>
						</Form.Item>
						<Form.Item
							style={{ marginBottom: "" }}
							label='SKU No'
							name='sku'
							rules={[
								{
									required: false,
									message: "Please input SKU!",
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							style={{ marginBottom: "" }}
							label='HSN Code'
							name='hsn_code'
							rules={[
								{
									required: false,
									message: "Please input HSN!",
								},
							]}>
							<Input />
						</Form.Item>
					</div>
				</div>

				{/* make a description item in form */}
				<Form.Item
					style={{ marginBottom: "20px" }}
					label='Product Description '
				    name='description'
                    rules={[
                        {
                          required: false,
                          message: "Product Description is required",
                        },
                   ]}
                 >
                   <Input
                     placeholder='Enter Product Description'
                     value={prodDescription} 
                     onChange={prodDescriptionHandler}
                   />
					{/* <ReactQuill
						value={prodDescription}
						onChange={prodDescriptionHandler}
						modules={textEditorModule}
						formats={textEditorFormats}
					/> */}
				</Form.Item>

                <Form.Item
					style={{ marginBottom: "5px" }}
					className={styles.addProductBtnContainer}>
				  <div className="max-sm:flex max-sm:gap-2">
						{
							!hideSaveAndCreate &&
							<Button
								type='primary'
								htmlType='submit'
								className="sm:mr-4"
								shape='round'
								loading={loader}
								onClick={() => {
									setLoader(true);
								}}>
								Save & Create
							</Button>
						}

					<Button
						type='primary'
						htmlType='submit'
						className="sm:ml-4"
						
						shape='round'
						loading={loader}
						onClick={() => {
							setIsFrom("saveAndClose")
							}}>
				        Save & Close
					</Button>
				  </div>
				</Form.Item>
				{/* <Form.Item
					style={{ marginBottom: "15px" }}
					className={styles.addProductBtnContainer}>
					<Button
						type='primary'
						htmlType='submit'
						shape='round'
						loading={loader}
						onClick={() => {
							setLoader(true);
						}}>
						Add Product
					</Button>
				</Form.Item> */}
			</Form>
		</>
	);
};

export default AddProd;
